<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 md8 lg8>
        <v-simple-table>
          <tbody>
            <tr>
              <td>Date</td>
              <td>{{ schedule.date }}</td>
              <td>Flight Take off</td>
              <td>{{ schedule.flight_take_off }}</td>
            </tr>
            <tr>
              <td>Duration</td>
              <td>{{ schedule.duration }}</td>
              <td>Net Amount</td>
              <td>{{ schedule.net_amount }}</td>
            </tr>
            <tr>
              <td>Seats Available</td>
              <td>{{ schedule.seats_available }}</td>
              <td>Seats</td>
              <td class="pt-2 pb-2">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 18pt;
                    height: 55px;
                    width: 200px;
                    font-weight: bold;
                    color: #fff;
                    background-color: #212121;
                    border-radius: 5px;
                    border: 1px solid #616161;
                  "
                >
                  <a
                    style="color: #fff; cursor: pointer; padding: 20px"
                    @click="decrementSeats"
                    ><span>-</span></a
                  >
                  <span
                    style="
                      padding-left: 30px;
                      padding-right: 30px;
                      border-left: 1px solid #424242;
                      border-right: 1px solid #424242;
                    "
                    >{{ seat }}</span
                  >
                  <a
                    style="color: #fff; cursor: pointer; padding: 20px"
                    @click="incrementSeats"
                    ><span>+</span></a
                  >
                </div>
                <!-- <v-select :items="seats" v-model="seat"></v-select> -->
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-layout row wrap class="grey darken-4 mt-5 pa-5">
          <v-flex xs12 md6 class="pa-2">
            <p class="primary--text ma-0 mb-1">Full Name *</p>
            <v-text-field
              label="Full Name"
              single-line
              outlined
              v-model="name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="pa-2">
            <p class="primary--text ma-0 mb-1">Email *</p>
            <v-text-field
              label="Email"
              single-line
              outlined
              v-model="email"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="pa-2">
            <p class="primary--text ma-0 mb-1">Phone *</p>
            <v-text-field
              label="Phone"
              single-line
              outlined
              v-model="phone"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="pa-2">
            <p class="primary--text ma-0 mb-1">Whatsapp *</p>
            <v-text-field
              label="Whatsapp"
              single-line
              outlined
              v-model="whatsapp"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="pa-2">
            <p class="primary--text ma-0 mb-1">Pick Up Location *</p>
            <v-text-field
              label="Pickup Location"
              single-line
              outlined
              v-model="pickup"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="pa-2">
            <p class="primary--text ma-0 mb-1">Drop Location *</p>
            <v-text-field
              label="Drop Location"
              single-line
              outlined
              v-model="drop"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="pa-2">
            <p class="primary--text ma-0 mb-1">Client Ref. No</p>
            <v-text-field
              label="Client Ref. No"
              single-line
              outlined
              v-model="client_reference"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="pa-2">
            <p class="primary--text ma-0 mb-1">Remarks</p>
            <v-text-field
              label="Remarks"
              single-line
              outlined
              v-model="remark"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-simple-table class="mt-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th class="text-left uppercase primary--text">Name</th>
                <th class="text-left uppercase primary--text">Age</th>
                <th class="text-left uppercase primary--text">Weight(Kg)</th>
                <th class="text-left uppercase primary--text">Height(Cm)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td class="pt-2 pb-2">
                  <v-text-field
                    single-line
                    outlined
                    label="Full Name"
                    v-model="preflight_name"
                  ></v-text-field>
                </td>
                <td class="pt-2 pb-2">
                  <v-text-field
                    single-line
                    outlined
                    label="Age"
                    v-model="preflight_age"
                  ></v-text-field>
                </td>
                <td class="pt-2 pb-2">
                  <v-text-field
                    single-line
                    outlined
                    label="Weight (Kg)"
                    v-model="preflight_weight"
                  ></v-text-field>
                </td>
                <td class="pt-2 pb-2">
                  <v-text-field
                    single-line
                    outlined
                    label="Height (Cm)"
                    v-model="preflight_height"
                  ></v-text-field>
                </td>
                <td class="pt-2 pb-2">
                  <v-btn
                    class="primary black--text"
                    x-large
                    @click="savePreFlight"
                    >Save</v-btn
                  >
                </td>
              </tr>
              <tr
                v-for="(item, index) in pre_flight_data"
                :key="item.createdAt"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.age }}</td>
                <td>{{ item.weight }}</td>
                <td>{{ item.height }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="mt-4">
          <ul class="grey--text">
            <li>
              A maximum of three children are permitted on a private charter.
            </li>
            <li>
              Children under the age of 11 must be accompanied by two adults.
            </li>
            <li>Children under the age of 2 are not allowed on the flights.</li>
            <li>
              Passengers weighing over 100 kg are not allowed on the flight.
            </li>
          </ul>
        </div>
      </v-flex>
      <v-flex xs12 md4 lg4 class="pl-5 pr-5">
        <div class="grey darken-4 pa-5 mb-5">
          <h3 class="primary--text"><b>Select Agent</b></h3>
          <p>Make booking on behalf of an Agent</p>
          <v-text-field
            single-line
            outlined
            v-model="searchText"
            label="Search for an Agent"
          ></v-text-field>

          <div
            v-for="item in filteredAgents"
            :key="item._id"
            class="pa-3 elevation-1 grey darken-3 mt-2 mb-2"
            style="border-radius: 5px; cursor: pointer"
            @click="selectAgent(item)"
          >
            <p class="ma-0">
              <b>{{ item.name }}</b>
            </p>
            <p class="caption ma-0">{{ item.ref_id }}</p>
          </div>
        </div>
        <div class="grey darken-4 pa-5">
          <div class="d-flex gap-2 justify-space-between">
            <p>Agent</p>
            <div v-if="isAgentAdded">
              <p class="ma-0 primary--text">{{ agent_account.name }}</p>
              <p class="ma-0 caption">{{ agent_account.ref_id }}</p>
              <p
                class="ma-0 caption"
                style="cursor: pointer"
                @click="clearAgent"
              >
                <u>Clear</u>
              </p>
            </div>
            <p v-else>-- NA --</p>
          </div>
          <div class="d-flex gap-2 justify-space-between mt-3 mb-3">
            <v-divider></v-divider>
          </div>
          <div class="d-flex gap-2 justify-space-between">
            <p>Private</p>
            <v-switch v-model="private_mode"></v-switch>
          </div>
          <div class="d-flex gap-2 justify-space-between">
            <p>Seats</p>
            <p>{{ seat }}</p>
          </div>
          <div class="d-flex gap-2 justify-space-between">
            <p>Total Amount</p>
            <p>{{ totalAmount }} AED</p>
          </div>
          <div class="d-flex gap-2 justify-space-between">
            <p>Agent Discount</p>
            <p>{{ agent_discount }} %</p>
          </div>
          <div class="d-flex gap-2 justify-space-between">
            <p>Net Amount</p>
            <p>{{ net_amount }} AED</p>
          </div>
          <div class="d-flex gap-2 justify-space-between">
            <p>Promo Code</p>
            <div class="text-right">
              <p class="text-right" v-if="isPromoApplied">
                <v-icon class="rotate primary--text mr-2">mdi-sale</v-icon
                ><u>{{ promo_code.code }}</u>
                <v-icon class="red--text" @click="promo_code = {}"
                  >mdi-close</v-icon
                >
                <br />
                <span class="caption grey--text">{{ promo_code.details }}</span>
                <br />
              </p>
              <p>
                <span style="cursor: pointer" @click="promo_dialog = true"
                  ><u>add promo code</u></span
                >
              </p>
            </div>
          </div>
          <div><v-divider></v-divider></div>
          <div class="d-flex gap-2 justify-space-between pt-5">
            <p class="ma-0 pt-1">Gross Payable Amount</p>
            <h2 class="primary--text ma-0 pa-0">{{ payable_amount }} AED</h2>
          </div>
          <div><v-divider></v-divider></div>
          <v-btn
            class="primary black--text mt-5"
            block
            x-large
            :disabled="!isBookButtonEnabled"
            @click="placeBooking"
            >Confirm Booking</v-btn
          >
        </div>
      </v-flex>
    </v-layout>
    <v-dialog v-model="promo_dialog" width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 primary--text grey darken-3">
          Apply Promo Code
        </v-card-title>
        <v-card-text>
          <p class="mt-5 primary--text">
            ENTER YOU PROMO CODE TO REDEEM GREAT DISCOUNTS!
          </p>
          <v-text-field
            single-line
            outlined
            label="promo"
            class="text-center"
            style="font-size: 32pt; text-align: center !important"
            v-model="promo_voucher"
          ></v-text-field>
          <v-btn
            class="primary black--text mt-5"
            x-large
            @click="checkAndApplyPromo"
            >Apply</v-btn
          >
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="promo_dialog = false">
            CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { BASE_URL } from "../../config";
import Axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      schedule: {},
      agent_account: {},
      seats: ["1", "2", "3", "4", "5"],
      seat: "1",
      name: "",
      email: "",
      phone: "",
      whatsapp: "",
      pre_flight_data: [],
      preflight_name: "",
      preflight_age: "",
      preflight_height: "",
      preflight_weight: "",
      private_mode: false,
      agent_discount: 0,
      promo_dialog: false,
      promo_code: {},
      promo_voucher: "",
      client_reference: "",
      remark: "",
      searchText: "",
      agents: [],
    };
  },
  computed: {
    ...mapGetters(["USER"]),
    filteredAgents() {
      const query = this.searchText.toLowerCase();
      if (query.length >= 3) {
        return this.agents.filter((item) =>
          JSON.stringify(item).toLowerCase().includes(query)
        );
      } else {
        return [];
      }
    },
    isBookButtonEnabled() {
      if (
        this.name != "" &&
        this.phone != "" &&
        this.email != "" &&
        this.whatsapp != "" &&
        this.pre_flight_data.length == this.seat
      ) {
        return true;
      } else {
        return false;
      }
    },
    isAgentAdded() {
      if (Object.keys(this.agent_account).length > 0) {
        return true;
      } else {
        return false;
      }
    },
    isPromoApplied() {
      if (Object.keys(this.promo_code).length > 0) {
        return true;
      } else {
        return false;
      }
    },
    totalAmount() {
      if (this.private_mode) {
        return this.schedule.private_net_amount;
      } else {
        return this.schedule.net_amount * this.seat;
      }
    },
    net_amount() {
      if (this.private_mode) {
        var _payable = this.schedule.private_net_amount;
        if (this.agent_discount != 0) {
          _payable = _payable * (1 - this.agent_discount / 100);
        }
        return Math.floor(_payable);
      } else {
        var _payable = this.schedule.net_amount * this.seat;
        if (this.agent_discount != 0) {
          _payable = _payable * (1 - this.agent_discount / 100);
        }
        return Math.floor(_payable);
      }
    },
    payable_amount() {
      var _payable = this.schedule.net_amount * this.seat;
      if (this.private_mode) {
        _payable = this.schedule.private_net_amount;
      }
      if (Object.keys(this.promo_code).length > 0) {
        if (this.promo_code.discount_type == "% discount") {
          var total_discount =
            parseInt(this.agent_account.commission) +
            parseInt(this.promo_code.discount);
          _payable = _payable * (1 - total_discount / 100);
        } else if (this.promo_code.discount_type == "Flat discount") {
          _payable =
            _payable * (1 - parseInt(this.agent_account.commission) / 100) -
            this.promo_code.discount;
        }
      } else if (this.agent_discount != 0) {
        _payable = _payable * (1 - this.agent_discount / 100);
      }
      return Math.floor(_payable);
    },
  },
  mounted() {
    this.fetchSchedule();
    this.fetchAgents();
    this.seat = this.$route.query.seat;
  },
  methods: {
    async fetchAgents() {
      let url = BASE_URL + "/agent";
      let { data } = await Axios.get(url);
      this.agents = data;
    },
    async selectAgent(agent) {
      this.agent_account = agent;
      this.searchText = "";
      this.agent_discount = this.agent_account.commission;
      this.fetchApplicablePromocode();
    },
    clearAgent() {
      this.agent_discount = 0;
      this.promo_code = {};
      this.agent_account = {};
    },
    async fetchApplicablePromocode() {
      let url = BASE_URL + "/promotion/auto-apply/list?type=Agent Voucher";
      let { data } = await Axios.get(url);
      this.promo_code = data;
    },
    async checkAndApplyPromo() {
      if (this.promo_voucher != "") {
        let url = BASE_URL + "/promotion/" + this.promo_voucher;
        let { data } = await Axios.get(url);
        if (data) {
          this.promo_code = data;
          this.promo_dialog = false;
        } else {
          this.promo_voucher = "";
          this.$toastr.e(
            "Please check the promo code you have added!",
            "Invalid Promo Code"
          );
        }
      } else {
        this.$toastr.e(
          "Please enter a valid promo code to continue!",
          "Invalid Promo Code"
        );
      }
    },
    async fetchSchedule() {
      let url = BASE_URL + "/schedule/" + this.$route.params.id;
      let { data } = await Axios.get(url);
      this.schedule = data;
    },
    async addPromoCode() {},
    incrementSeats() {
      if (this.seat < this.schedule.seats_available) {
        this.seat = (parseInt(this.seat) + 1).toString();
      }
    },
    decrementSeats() {
      if (this.seat > 1) {
        this.seat = (parseInt(this.seat) - 1).toString();
      }
    },
    savePreFlight() {
      let childrenCount = this.pre_flight_data.filter(
        (pax) => pax.age < 11
      ).length;
      let infantsCount = this.pre_flight_data.filter(
        (pax) => pax.age < 2
      ).length;
      let adultsCount = this.pre_flight_data.filter(
        (pax) => pax.age >= 18
      ).length;
      if (this.pre_flight_data.length >= this.seat) {
        this.$toastr.e(
          "Passenger count cannot be more than seat selected",
          "Cannot add Data"
        );
        return;
      }
      if (this.weight > 100) {
        this.$toastr.e("Passengers weighing over 100 kg are not allowed on the flight.", "Cannot add Data");
        return;
      }
      if (this.preflight_age <= 2) {
        this.$toastr.e(
          "Children under the age of 2 are not allowed on the flights.",
          "Cannot add Data"
        );
        return;
      }
      if (adultsCount <=2 && this.preflight_age < 11) {
        this.$toastr.e("Children under the age of 11 must be accompanied by two adults. Please enter adult data first", "Cannot add Data");
        return;
      }
      if (
        this.preflight_age < 11 &&
        childrenCount >= 3
      ) {
        this.$toastr.w(
          "A maximum of three children under the age of 11 are permitted on a private charter.",
          "Cannot add Data"
        );
        return;
      }
      let pax = {
        name: this.preflight_name,
        age: this.preflight_age,
        height: this.preflight_height,
        weight: this.preflight_weight,
      };
      this.pre_flight_data.push(pax);
      this.preflight_name = "";
      this.preflight_age = "";
      this.preflight_height = "";
      this.preflight_weight = "";
    },

    async placeBooking() {
      let payload = {
        mobile_number: this.phone,
        name: this.name,
        email: this.email,
        whatsapp: this.whatsapp,
        date: this.schedule.date,
        time: this.schedule.time,
        seats: this.seat,
        channel: "AC",
        amount: this.totalAmount,
        private: this.private,
        net_amount: this.payable_amount,
        client_reference: this.client_reference,
        remarks: this.remark,
        payment_mode: "AC Direct",
        ac_unique_code: this.schedule.ac_unique_code,
        duration: this.schedule.duration,
        booking_made_by: this.USER.admin._id,
        pre_flight_data: this.pre_flight_data,
      };
      let url = BASE_URL + "/booking/customer-care";
      if (this.isAgentAdded) {
        payload.agent = this.agent_account._id;
        url = BASE_URL + "/booking/agent";
      }
      let { data } = await Axios.post(url, payload);
      console.log(JSON.stringify(data));
      this.$toastr.s("New booking created successfully", "Booking Placed");
      this.$router.push({
        name: "AgentBookingDetail",
        params: { id: data.booking.booking_id },
      });
    },
  },
};
</script>
  
  <style>
.v-input--selection-controls {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: -5px;
}
.rotate {
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>